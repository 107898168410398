import { Button, Grid2 } from "@mui/material";
import React from "react";
import BoxItem from "./BoxItem";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const BigBoxItems = (props) => {
  const navigate = useNavigate();
  const details = props.lBox;
  const path = props.path;
  const cookies = new Cookies();

  return (
    <Grid2 width={"100%"} marginY={"1%"} height={"100%"}>
      <Button
        sx={{
          padding: "0px",
          textTransform: "none",
          width: "100%",
          height: "100%",
        }}
        onClick={() => {
          navigate(details?.appRoute);
          // cookies.set("_selectedPage", details?.AIDESC, {
          //   path: "/",
          //   expires: new Date(Date.now() + 2592000),
          // });
          // cookies.set(
          //   "_selectedApp",
          //   details?.AIDESC + " / " + details?.BJTITLE,
          //   {
          //     path: "/",
          //     expires: new Date(Date.now() + 2592000),
          //   }
          // );
        }}
      >
        <BoxItem
          title={details?.appTitle}
          description={details?.appDesc}
          image={details?.appImageUrl}
        />
      </Button>
    </Grid2>
  );
};

export default BigBoxItems;
