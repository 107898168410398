// Importing necessary components and functions from MUI and Redux
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { logOut } from "../../../Services/Redux/Reducers/authSliceReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, IconButton } from "@mui/material";

// Define two arrays for the navigation menu and user settings menu
const pages = ["Products", "Pricing", "Blog"]; // Example pages for the navigation
const settings = ["My Profile", "Sign Out"]; // User settings options (Profile and Logout)

function ResponsiveAppBar() {
  // State for managing the anchor (position) of the navigation and user menus
  const [anchorElNav, setAnchorElNav] = React.useState(null); // Navigation menu state
  const [anchorElUser, setAnchorElUser] = React.useState(null); // User menu state
  const navigate = useNavigate();
  const cookies = new Cookies();

  // Dispatch function to trigger actions
  const dispatch = useDispatch();

  // Get userData from the Redux store to display the user's information
  const userData = useSelector((state) => state.auth.userData);
  const selectedMenu = useSelector((state) => state.auth.selectedMenu);
  const userInfo = userData?.userData?.userInfo;

  // Function to open the navigation menu
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  // Function to open the user menu
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // Function to close the navigation menu
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // Function to close the user menu and log out if "Logout" is selected
  const handleCloseUserMenu = (e) => {
    if (e === "Sign Out") {
      // Dispatch the logout action and reload the page to reset the state
      dispatch(logOut(false));
      navigate("/");
      window.location.reload();
    }
    setAnchorElUser(null); // Close the user menu
  };

  return (
    // AppBar component from MUI, used to display the navigation bar
    <AppBar
      position="static"
      sx={{
        width: "100%", // AppBar takes up the full width of the page
        backgroundColor: "#00557D", // Set the background color to a custom blue shade
      }}
    >
      <Grid container>
        <Grid
          item
          xs={10}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "2rem",
          }}
        >
          {selectedMenu?.pageDesc}
        </Grid>
        <Grid item xs={2}>
          <Container
            sx={{
              display: "flex",
              justifyContent: "flex-end", // Align toolbar content to the right
              maxWidth: "100% !important", // Ensure the container takes up full width
            }}
          >
            <Toolbar disableGutters>
              {/* User Info and Avatar */}
              <Box sx={{ flexGrow: 0 }}>
                {/* Display the full name of the user */}
                <span
                  style={{
                    fontWeight: "bold",
                    marginRight: "20px",
                  }}
                >
                  {userInfo?.fullName}
                </span>

                {/* Avatar and Tooltip for user settings */}
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {/* Avatar component for user's profile picture
                <Avatar alt="Ali Rida" src="/static/images/avatar/2.jpg" /> */}
                    {anchorElUser ? (
                      <KeyboardArrowDownIcon
                        sx={{ color: "white", width: "25px", height: "25px" }}
                      />
                    ) : (
                      <KeyboardArrowUpIcon
                        sx={{ color: "white", width: "25px", height: "25px" }}
                      />
                    )}
                  </IconButton>
                </Tooltip>

                {/* Menu for user settings (Profile and Logout) */}
                <Menu
                  sx={{ mt: "45px" }} // Position the menu slightly below the avatar
                  id="menu-appbar"
                  anchorEl={anchorElUser} // Attach the menu to the anchor position (user avatar)
                  anchorOrigin={{
                    vertical: "top", // Open the menu from the top
                    horizontal: "right", // Align the menu to the right
                  }}
                  keepMounted // Ensure the menu stays in the DOM even when closed
                  transformOrigin={{
                    vertical: "top", // Set the origin for menu transformations
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)} // Menu is open if anchorElUser is not null
                  onClose={handleCloseUserMenu} // Close the menu when clicking outside or selecting an item
                >
                  {/* Map through the settings array and create a menu item for each */}
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting} // Unique key for each menu item
                      onClick={() => handleCloseUserMenu(setting)} // Close the menu when an item is clicked
                    >
                      <Typography
                        sx={{ textAlign: "center", fontSize: "1.2rem" }}
                      >
                        {setting}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default ResponsiveAppBar; // Export the component for use in other parts of the application
