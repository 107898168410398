// export const userLoginMenu = [];
// export const userLoginPages = [];
// export const userLoginInfo = {};
// export const currPagedesc = "Welcome";
// export const jwtToken = {};
// export const userSystems = [];
export const HTTP_URL = process.env.REACT_APP_API_GW_URL;
export const SOCKET_IO_URL = process.env.REACT_APP_API_SOCKETIO_URL;

// export default userLoginMenu;
