// DialogCompanyTemplate.js
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DialogCompanyTemplate = ({
  companyIdRowSelected,
  openCompanyIDPopUp,
}) => {
  const [companyID, setCompanyID] = useState(
    companyIdRowSelected.companyId || ""
  );

  const companyIDSelected = useSelector(
    (state) => state?.addressBook?.companyIDSelected // Accessing masterInfoData from the Redux store
  );

  // const handleInputChange = (event) => {
  //   setCompanyID(event.target.value);
  //   if (props.onChange) {
  //     props.onChange(event.target.value);
  //   }
  // };

  return (
    <div>
      <div className="e-field">
        <label>Company ID</label>
        <div style={{ position: "relative" }}>
          <input
            type="text"
            name="companyId"
            value={companyIDSelected ? companyIDSelected : companyID}
            // onChange={handleInputChange}
            style={{
              paddingRight: "30px",
              width: "100%",
              boxSizing: "border-box",
            }}
            className="e-input"
            disabled
          />
          <i
            className="fa fa-search"
            aria-hidden="true"
            style={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              color: "#666",
              cursor: "pointer",
            }}
            onClick={openCompanyIDPopUp}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default DialogCompanyTemplate;
