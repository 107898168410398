import { Box } from "@mui/material";
import React from "react";

const FilterComponent = ({ children }) => {
  return (
    <Box
      sx={{
        padding: "1%",
        paddingX: "4%",
        backgroundColor: "rgb(226, 226, 226)",
      }}
      boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.2),0 2px 10px 0 rgba(0, 0, 0, 0.19)"
    >
      {children}
    </Box>
  );
};

export default FilterComponent;
