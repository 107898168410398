import * as React from "react"; // Importing React and its components
import {
  Button, // Material-UI button component
  Dialog, // Material-UI dialog component
  DialogActions, // Material-UI dialog actions section
  DialogContent, // Material-UI dialog content section
  DialogTitle, // Material-UI dialog title section
  Grid, // Material-UI grid layout component
} from "@mui/material"; // Importing Material-UI components

// AlertDialog is a functional component for displaying a customizable dialog
function AlertDialog(props) {
  // Set the width of the dialog, defaulting to 100% if not specified
  const width = props?.width ? props?.width : "100%";

  return (
    <Dialog
      open={props.open} // Control the dialog's open state
      onClose={props.handleClose} // Function to call on dialog close
      aria-labelledby="alert-dialog-title" // Accessibility label for the title
      aria-describedby="alert-dialog-description" // Accessibility label for the content
      // Ensuring the Dialog renders in a high part of the DOM
      container={document.body} // Rendering the dialog in the body of the document
      sx={{
        zIndex: 200000, // Force a high z-index for the dialog container
        position: "absolute", // Position the dialog absolutely
        ".css-c09uja-MuiPaper-root-MuiDialog-paper": {
          width: width, // Set the width of the dialog to the specified width
        },
      }}
    >
      {/* Title section of the dialog */}
      <DialogTitle
        id="alert-dialog-title" // Accessibility label for the title
        sx={{ color: "#064987", fontWeight: "bold", fontSize: "1.2em" }} // Style for the title
        display={"flex"} // Use flexbox for layout
        justifyContent={"center"} // Center the title
      >
        {props.title} {/* Display the title passed as a prop */}
      </DialogTitle>

      {/* Content section of the dialog */}
      <DialogContent>
        <Grid id="alert-dialog-description" sx={props?.sx}>
          {" "}
          {/* Grid layout for content */}
          {props.content} {/* Display the content passed as a prop */}
        </Grid>
      </DialogContent>

      {/* Actions section of the dialog (buttons) */}
      <DialogActions>
        <Grid container>
          {" "}
          {/* Use grid layout for action buttons */}
          <Grid item xs={8}></Grid> {/* Empty grid item for spacing */}
          <Grid item xs={1.8}>
            {/* Conditionally render the button if buttonTitle is provided */}
            {props.buttonTitle !== "" && (
              <Button
                autoFocus // Focus on this button when dialog opens
                onClick={props.buttonClick} // Function to call on button click
                sx={{
                  backgroundColor: "#064987!important", // Style for the button background
                  color: "white", // Button text color
                  width: "100%", // Make button width 100%
                }}
                disabled={props.buttonDisabled} // Disable button based on prop
              >
                {props.buttonTitle}{" "}
                {/* Display the button title passed as a prop */}
              </Button>
            )}
          </Grid>
          <Grid item xs={0.1}></Grid> {/* Empty grid item for spacing */}
          <Grid item xs={1.8}>
            <Button
              autoFocus // Focus on this button when dialog opens
              sx={{
                backgroundColor: "#7d7d7d", // Style for the cancel button
                color: "white", // Cancel button text color
                width: "100%", // Make button width 100%
              }}
              onClick={props.handleClose} // Function to call on cancel button click
            >
              Cancel {/* Label for the cancel button */}
            </Button>
          </Grid>
          <Grid item xs={0.3}></Grid> {/* Empty grid item for spacing */}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog; // Export the AlertDialog component for use in other parts of the application
