import { Box, CircularProgress, Grid } from "@mui/material"; // Importing Material-UI components for layout and loading indicator
import React, { useState } from "react"; // Importing React and useState hook
import FilterComponent from "../../../Common/Filter";
import ItemsInfoFilterReturn from "./FilterReturn";
import ItemsMasterBody from "./Body";
import { useSelector } from "react-redux";

const ItemsMasterComponent = () => {
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status

  const itemsMasterData = useSelector(
    (state) => state?.inventory?.itemsMasterData // Selecting masterInfoData from Redux state
  );

  const brandList = useSelector(
    (state) => state?.inventory?.brandList // Selecting masterInfoData from Redux state
  );

  const brands = brandList?.data?.map((list) => {
    return {
      id: Math.random(),
      brandDesc: list?.UVDESC,
    };
  });

  return (
    <Box sx={{ color: "black" }}>
      {" "}
      {/* Main container with text color set to black */}
      <Grid sx={{ backgroundColor: "rgb(226, 226, 226)" }}>
        <FilterComponent
          children={<ItemsInfoFilterReturn setIsLoading={setIsLoading} />} // Rendering filter component with loading state handler
        />
        {!isLoading && itemsMasterData?.length > 0 && (
          <ItemsMasterBody brands={brands} />
        )}
      </Grid>
      {isLoading && (
        <div
          style={{
            width: "100%", // Full width
            display: "flex", // Flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <CircularProgress
            sx={{
              color: "#1976d2", // Spinner color
              width: "200px !important", // Setting width
              height: "200px !important", // Setting height
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default ItemsMasterComponent; // Exporting the component for use in other parts of the application
