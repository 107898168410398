// Import necessary libraries and components
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  setCredentials,
  setSelectedMenu,
  setSocketAdmin,
  setSocketLabels,
  setSocketProject,
} from "../../../Services/Redux/Reducers/authSliceReducer";
import { useLoginMutation } from "../../../Services/RTKQuery/auth/authApiSlice";
import BasicTextFields from "../../Common/TextField";
import BasicButtons from "../../Common/Button";
import CheckboxLabels from "../../Common/Checkbox";
import Cookies from "universal-cookie";
import SocketIO from "../../../Services/SocketIO";

// WrappedTextBox component to forward refs to the child component
const WrappedTextBox = React.forwardRef((props, ref) => {
  return (
    <div>
      <BasicTextFields
        {...props}
        inputRef={(element) => {
          if (element && ref) {
            ref.current = element; // Pass reference to the input field
          }
        }}
      />
    </div>
  );
});

// Main Login component
const Login = () => {
  const userRef = useRef(); // Ref for the username input field
  const errRef = useRef(); // Ref for error message display
  const [username, setUsername] = useState(""); // State for username input
  const [pwd, setPwd] = useState(""); // State for password input
  const [errMsg, setErrMsg] = useState(""); // State for error message
  const navigate = useNavigate(); // Hook to navigate to different routes
  const cookies = new Cookies(); // Cookie management

  // Redux hooks for login mutation and dispatching actions
  const [login, { isLoading, isError, error }] = useLoginMutation();
  const dispatch = useDispatch();

  // Focus on the username input when the component loads
  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);

  // Clear error message whenever username or password changes
  useEffect(() => {
    setErrMsg("");
  }, [username, pwd]);

  // Handle cookies for saving user session data
  const handleCookie = (name, data) => {
    cookies.set(name, data, {
      path: "/", // Set cookie available throughout the site
      expires: new Date(Date.now() + 2592000), // Expire in 30 days
    });
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload on form submission

    try {
      // Attempt to log in the user
      const userData = await login({
        username: username,
        password: pwd,
      }).unwrap();
      // If login is successful, process user data
      if (userData?.reply === "loginSuccess") {
        const _userAll = {
          userid: userData?.userId,
          username: username,
          token: userData?.token,
        };
        const _userMenu = userData?.userMenu;
        // Dispatch user credentials to the Redux store
        dispatch(
          setCredentials({
            ...userData,
            userAll: _userAll,
            userData: userData,
            userMenu: userData?.userMenu,
            user: true,
          })
        );

        // Flatten and collect all socketLabels
        let allLabels = _userMenu
          ?.flatMap((menu) => menu.pageSections)
          .flatMap((section) => section.sectionApps)
          .flatMap((app) => app.socketLabels || []);

        // Step 2: Split each label by comma and flatten the result
        allLabels = allLabels.flatMap((label) =>
          label.includes(",") ? label.split(",") : [label]
        );

        // Step 3: Remove duplicates
        const _uniquePagesLabel = [
          ...new Set(allLabels.map((label) => label.trim())),
        ];

        const filterLabels = (label) => {
          const filtered = _uniquePagesLabel.filter((e) => {
            if (e?.indexOf(",") > -1) {
              const isTrue =
                e?.split(", ")?.[0] === label || e?.split(", ")?.[1] === label;
              return isTrue;
            } else {
              return e?.includes(label);
            }
          });
          return filtered?.length > 0;
        };

        if (filterLabels("admin")) {
          const socketadmin = SocketIO(
            userData?.token,
            process.env.REACT_APP_API_SOCKETIOADMIN_PATH,
            false
          ).connect();
          dispatch(setSocketAdmin(socketadmin));
        }

        if (filterLabels("project")) {
          const socketproject = SocketIO(
            userData?.token,
            process.env.REACT_APP_API_SOCKETIOPROJECT_PATH,
            false
          ).connect();
          dispatch(setSocketProject(socketproject));
        }

        dispatch(setSelectedMenu(_userMenu?.[0]));
        dispatch(setSocketLabels(_uniquePagesLabel));

        // Save user data in cookies
        handleCookie("_userData", JSON.stringify(userData));
        handleCookie("_userMenu", JSON.stringify(userData?.userMenu));
        handleCookie("_user", JSON.stringify(true));
        handleCookie("_userAll", JSON.stringify(_userAll));
        handleCookie("_selectedMenu", JSON.stringify(_userMenu?.[0]));
        handleCookie("_socketLabels", _uniquePagesLabel);
        handleCookie("_location", "/main/home");

        // Clear form inputs
        setUsername("");
        setPwd("");

        // Navigate to the main page
        // navigate("/main");
      } else {
        // If login fails, display the error message from the response
        setErrMsg(userData?.message);
      }
    } catch (err) {
      // Handle different error responses based on the status code
      if (!err?.originalStatus) {
        setErrMsg("No Server Response");
      } else if (err.originalStatus === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.originalStatus === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus(); // Focus on error message after catching error
    }
  };

  // Handle username input changes
  const handleUserInput = (e) => setUsername(e.target.value);

  // Handle password input changes
  const handlePwdInput = (e) => setPwd(e.target.value);

  // Loading content while request is processing
  const content = isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <section
      className="login"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ded9ee",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "35%",
          backgroundColor: "#1976d2",
          height: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ borderRadius: "7.5px" }}>
          <img src="/taqa_logo.png" width={"100%"} alt="Company Logo" />
        </div>
      </div>
      <div
        style={{
          width: "75%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "40%" }}>
          <div
            style={{ marginBottom: "5%", textAlign: "center", color: "black" }}
          >
            <h1>Login</h1>
            <h6>Please enter your Username and your Password</h6>
          </div>

          {/* Display error message if it exists */}
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
            style={{ marginBottom: "5%", textAlign: "center" }}
          >
            {errMsg}
          </p>

          {/* Form for login */}
          <form onSubmit={handleSubmit}>
            <WrappedTextBox
              value={username}
              ref={userRef}
              id="username"
              onChange={handleUserInput}
              placeholder="Username"
              type="text"
              sx={{
                backgroundColor: "white",
                ".MuiInputBase-input": {
                  padding: "3%",
                },
              }}
            />
            <WrappedTextBox
              value={pwd}
              id="password"
              onChange={handlePwdInput}
              placeholder="Password"
              type="password"
              sx={{
                backgroundColor: "white",
                ".MuiInputBase-input": {
                  padding: "3%",
                },
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CheckboxLabels
                sx={{
                  color: "black",
                  fontSize: "1rem",
                  ".css-1edfpdg-MuiTypography-root": {
                    fontSize: "1.2rem",
                  },
                }}
                label="Remember Me"
              />
              <a
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "black",
                }}
                onClick={() => navigate("/forgetPassword")}
                // href="forgetPassword"
              >
                Forget Password?
              </a>
            </div>
            <BasicButtons
              onClick={handleSubmit}
              style={{
                marginTop: "3%",
                padding: "2%",
              }}
              text="Sign In"
              type="submit"
              disabled={username === "" || pwd === ""} // Disable button if inputs are empty
            />
          </form>
        </div>
      </div>
    </section>
  );

  // Return the login form or loading screen
  return content;
};

export default Login;
