import React, { useEffect, useRef } from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import styles from "./sidenav.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Muicon from "@mui/icons-material";
import { setSelectedMenu } from "../../../Services/Redux/Reducers/authSliceReducer";
import Cookies from "universal-cookie";

export default function Sidenav({ open, setopen }) {
  const sidebarRef = useRef(null); // Reference for sidebar
  const userMenu = useSelector((state) => state.auth.userMenu);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const selectedMenu = cookies.get("_selectedMenu");

  console.log(selectedMenu);

  // Detect click outside sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setopen(false); // Close the sidebar when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setopen]);

  const GenerateIcon = (variation, props = {}) => {
    const IconName = Muicon[variation]
      ? Muicon[variation]
      : Muicon["ArrowForwardIos"];
    const selected = props === selectedMenu?.pageId;
    return (
      <IconName
        sx={{
          width: !open ? "60%" : "20%",
          height: !open ? "60%" : "20%",
          marginRight: !open ? "0%" : "15px",
          color: selected && "rgb(0 255 0)",
        }}
        {...props}
      />
    );
  };

  const toggleOpen = () => {
    setopen(!open);
  };

  const onSelectMenu = (item) => {
    navigate("/main/home");
    dispatch(setSelectedMenu(item));
    cookies.set("_selectedMenu", JSON.stringify(item), {
      path: "/",
      expires: new Date(Date.now() + 2592000),
    });
  };

  return (
    <div
      ref={sidebarRef} // Attach ref to the sidebar
      className={open ? styles.sidenav : styles.sidenavClosed}
    >
      {open && (
        <div
          style={{
            borderRadius: "7.5px",
          }}
        >
          <img src="/taqa_logo.png" width={"100%"} />
        </div>
      )}
      {!open ? (
        <button
          className={styles.menuBtn}
          style={{ width: "85%" }}
          onClick={toggleOpen}
        >
          <KeyboardDoubleArrowRightIcon
            sx={{ width: "1.2em", height: "1.2em" }}
          />
        </button>
      ) : (
        <Divider
          textAlign="left"
          sx={{
            "&::before, &::after": {
              borderColor: "#a5a5a5",
            },
            marginTop: !open ? "40%" : "0%",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <button className={styles.menuBtn} onClick={toggleOpen}>
              <KeyboardDoubleArrowLeftIcon
                sx={{ width: "1.2em", height: "1.2em" }}
              />
            </button>
          </Box>
        </Divider>
      )}

      {userMenu?.map((item) => {
        return (
          <div key={item.pageId} onClick={() => onSelectMenu(item)}>
            <NavLink
              className={styles.sideitem}
              to={item?.link}
              style={{
                display: "flex",
                justifyContent: !open ? "center" : "left",
              }}
              onClick={() => setopen(false)}
            >
              {GenerateIcon(
                item?.pageIcon ? item?.pageIcon : "ArrowForwardIos",
                item?.pageId
              )}
              {open && (
                <span
                  style={{
                    color:
                      item?.pageId === selectedMenu?.pageId && "rgb(0 255 0)",
                  }}
                  className={styles.linkText}
                >
                  {item.pageDesc}
                </span>
              )}
            </NavLink>
            <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <Divider
                sx={{
                  borderColor: "rgb(150 150 150 / 54%)",
                  paddingX: "5%",
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
