import React, { useEffect, useRef, useState } from "react";
import BasicTextFields from "../../Common/TextField";
import BasicButtons from "../../Common/Button";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { usePasswordResetMutation } from "../../../Services/RTKQuery/auth/authApiSlice";
import { useDispatch } from "react-redux";
import { setNotificationSnackBar } from "../../../Services/Redux/Reducers/authSliceReducer";
import OTPInput from "../../Common/OTPInput";
import ChangePasswordComponent from "./ChangePassword";

const WrappedTextBox = React.forwardRef((props, ref) => {
  return (
    <div>
      <BasicTextFields
        {...props}
        inputRef={(element) => {
          if (element && ref) {
            ref.current = element;
          }
        }}
      />
    </div>
  );
});

const ForgetPasswordComponent = () => {
  const [username, setUsername] = useState("");
  const userRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordReset, { isLoading, isError, error }] =
    usePasswordResetMutation();
  const [openCheckCode, setOpenCheckCode] = useState(false);
  const [ID, setID] = useState("");

  const handleUserInput = (e) => setUsername(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Attempt to log in the user
    const reset = await passwordReset({
      username: username,
    }).unwrap();

    // If login is successful, process user data
    if (reset?.message?.type === "info") {
      setOpenCheckCode(true);
      setID(reset?.requestId);
    } else {
      setUsername("");
    }
    dispatch(
      setNotificationSnackBar({
        open: true,
        message: reset?.message?.text,
        type: reset?.message?.type,
      })
    );
  };

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);

  const onCloseCheck = () => {
    setOpenCheckCode(false);
  };

  return (
    <section
      className="login"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(222, 217, 238)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "35%",
          backgroundColor: "rgb(25, 118, 210)",
          height: "100%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            // border: "1px solid black",
            borderRadius: "7.5px",
            // width: "70%",
          }}
        >
          <img src="/taqa_logo.png" width={"100%"} />
        </div>
      </div>
      {!isLoading && !openCheckCode && (
        <div
          style={{
            width: "75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "40%" }}>
            <div
              style={{
                backgroundColor: "black",
                width: "8%",
                borderRadius: "7.5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <ArrowBackIcon sx={{ height: "1.5em" }} />
            </div>
            <div
              style={{
                marginBottom: "5%",
                textAlign: "center",
                color: "black",
              }}
            >
              <h1>Forget Password</h1>
              <h6>Please enter your Username</h6>
            </div>

            <form onSubmit={handleSubmit}>
              <WrappedTextBox
                value={username}
                ref={userRef}
                id="username"
                onChange={handleUserInput}
                placeholder="Username"
                type="text"
                autoComplete="off"
              />
              <BasicButtons
                style={{ marginTop: "3%" }}
                text={
                  !isLoading ? (
                    "Send"
                  ) : (
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "20px !important",
                        height: "20px !important",
                      }}
                    />
                  )
                }
                type="submit"
                disabled={username === ""}
              />
            </form>
          </div>
        </div>
      )}
      {isLoading && !openCheckCode && (
        <div
          style={{
            width: "75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "black",
              width: "200px !important",
              height: "200px !important",
            }}
          />
        </div>
      )}
      {openCheckCode && (
        <ChangePasswordComponent onCloseCheck={onCloseCheck} id={ID} />
      )}
    </section>
  );
};

export default ForgetPasswordComponent;
