import { io } from "socket.io-client";
import { SOCKET_IO_URL } from "../GlobalVar/commonVar";

const SocketIO = (token, path, auto) => {
  const socket = io(SOCKET_IO_URL, {
    autoConnect: auto,
    path: path,

    upgrade: false,
    reconnection: true, // Enable auto-reconnection
    reconnectionAttempts: Infinity, // Attempt to reconnect forever
    reconnectionDelay: 1000, // Start with a 1-second delay
    reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts
    query: {
      token: token,
    },
    transports: ["websocket"], // use WebSocket first, if available
    rejectUnauthorized: false,
  });
  return socket;
};

export default SocketIO;
