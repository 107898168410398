import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "../../../components/Pages/MainLayout";

const RequireAuth = () => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  // If the user is authenticated, render the protected routes (MainLayout).
  if (user) {
    return <MainLayout />;
  }

  // If not authenticated, redirect to login page and store the last location
  return <Navigate to="/" replace state={{ from: location }} />;
};

export default RequireAuth;
