import "./index.css";
import * as React from "react";
import { useState } from "react";
import { OtpInputComponent } from "@syncfusion/ej2-react-inputs";

const OTPInput = ({ value, onChange }) => {
  const [otpTextboxValue, setOtpValue] = useState("5201");
  //   const [separator, setSeparator] = useState("-");
  //   const [placeholder, setPlaceholder] = useState("X");
  //   const [length, setLength] = useState(4);
  //   const [disabled, setDisabled] = useState(false);
  //   const [validationValue, setValidationvalue] = useState("");
  //   const [modeValue, setModevalue] = useState("outlined");
  const modeData = [
    { Mode: "outlined", Text: "Outlined" },
    { Mode: "underlined", Text: "Underlined" },
    { Mode: "filled", Text: "Filled" },
  ];
  const fields = { value: "Mode", text: "Text" };
  const validationData = [
    { Status: "", Text: "None" },
    { Status: "e-success", Text: "Success" },
    { Status: "e-warning", Text: "Warning" },
    { Status: "e-error", Text: "Error" },
  ];
  const validationFields = { value: "Status", text: "Text" };
  function OtpValueChange(args) {
    setOtpValue(args.value);
  }

  return (
    <div
      className="control-pane"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div
        className="control-section"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="col-lg-8"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            id="otp-container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <OtpInputComponent
              style={{ display: "flex", justifyContent: "center" }}
              id="basicOtp"
              separator={"-"}
              placeholder={"X"}
              value={value}
              length={4}
              //   cssClass={validationValue}
              stylingMode={"outlined"}
              input={(e) => onChange(e?.value)}
            ></OtpInputComponent>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OTPInput;
