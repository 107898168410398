import React from "react"; // Importing React library
import "./myAlertDialog.css"; // Importing CSS styles for the alert dialog

// MyAlertDialog is a functional component that displays a customizable alert dialog
const MyAlertDialog = ({
  open, // Boolean to control the visibility of the dialog
  handleClose, // Function to handle the closing of the dialog
  title, // Title text to display at the top of the dialog
  content, // Main content text to display in the dialog
  buttonTitle, // Text for the primary action button
  buttonClick, // Function to handle click events for the primary action button
}) => {
  return (
    <div className="container">
      {" "}
      {/* Main container for the dialog */}
      <div
        style={{
          textAlign: "center", // Center-align the title
          color: "#064987", // Set title color
          fontWeight: "bold", // Make title bold
          fontSize: "1.3em", // Set font size for title
          marginBottom: "2%", // Add margin below the title
        }}
      >
        {title} {/* Render the title prop */}
      </div>
      <div>{content}</div> {/* Render the content prop */}
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: "3%" }} // Flexbox for button alignment
      >
        <button className="buttonSave" onClick={buttonClick}>
          {" "}
          {/* Primary action button */}
          {buttonTitle} {/* Render the button title */}
        </button>
        <button onClick={handleClose} className="buttonClose">
          {" "}
          {/* Close button */}
          Close {/* Static text for the close button */}
        </button>
      </div>
    </div>
  );
};

export default MyAlertDialog; // Exporting the MyAlertDialog component for use in other parts of the application
