import React from "react";

const getUDCApi = (socket, type1, type2, dispatch, setState, setIsLoading) => {
  socket.emit(
    "automation:web:admin:usr:v1",
    {
      request: "udcData",
      udcData: {
        type: "getUdc",
        getUdc: {
          type1: type1,
          type2: type2,
        },
      },
    },
    (response) => {
      dispatch(setState(response));
      if (setIsLoading) {
        setIsLoading(false);
      }
      console.log("response");
      console.log(response);
    }
  );
};

export default getUDCApi;
