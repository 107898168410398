import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./textField.css";

export default function BasicTextFields(props) {
  return (
    <Box
      sx={{ "& > :not(style)": { width: "100%" } }}
      noValidate
      autoComplete="off"
    >
      <TextField
        {...props}
        variant="outlined"
        autoComplete="off"
        className="custom-textfield"
      />
    </Box>
  );
}
