import { Box, Grid } from "@mui/material"; // Importing Box and Grid components from MUI for layout
import React from "react"; // Importing React
import BasicTextFields from "../../../../Common/TextField"; // Importing custom BasicTextFields component for input fields
import { useDispatch, useSelector } from "react-redux"; // Importing Redux hooks for state management
import {
  setMasterInfoData, // Redux action to set master info data
} from "../../../../../Services/Redux/Reducers/addressBookReducer";
import BasicSelect from "../../../../Common/Select"; // Importing custom BasicSelect component for dropdown
import BasicButtons from "../../../../Common/Button"; // Importing custom BasicButtons component for button
import {
  setBrandList,
  setItemsMasterData,
  setItemsMasterFilter,
} from "../../../../../Services/Redux/Reducers/inventoryReducer";
import getUDCApi from "../../../../../Services/Common/UDCAPI";

const ItemsInfoFilterReturn = ({ setIsLoading }) => {
  // Selecting the master info filter state from the Redux store
  const itemsMasterFilter = useSelector(
    (state) => state?.inventory?.itemsMasterFilter
  );
  const dispatch = useDispatch(); // Initializing Redux dispatch
  const socketproject = useSelector((state) => state.auth.socketproject); // Accessing socketproject from the Redux store

  // Function to handle input changes and update the filter in the Redux store
  const handleChange = (e, item) => {
    dispatch(
      setItemsMasterFilter({
        ...itemsMasterFilter, // Retain previous filter values
        [item]: e?.target?.value, // Update the specific filter item with the new value
      })
    );
  };

  // Function to handle search logic by sending a socket event
  const onSearch = () => {
    setIsLoading(true); // Set loading state to true when the search starts
    socketproject.emit(
      "automation:web:admin:usr:v1", // Socket event name
      {
        request: "inventory",
        inventory: {
          type: "items", // Specifying the search type
          items: {
            action: "search",
            itemNb: itemsMasterFilter?.itemNb, // Pass full name filter
            itemDesc: itemsMasterFilter?.itemDesc, // Pass address number filter
          },
        },
      },
      (response) => {
        dispatch(setItemsMasterData(response?.data));
        getUDCApi(
          socketproject,
          "IV",
          "BR",
          dispatch,
          setBrandList,
          setIsLoading
        );
        // setIsLoading(false); // Set loading state to false when the search completes
      }
    );
  };

  return (
    <Box>
      <Grid container>
        {" "}
        {/* Grid container for layout */}
        {/* Address number input field */}
        <Grid sx={{ width: "10%" }}>
          <BasicTextFields
            value={itemsMasterFilter?.itemNb} // Setting the value from the filter state
            id="itemNb" // Assigning a unique ID
            onChange={(e) => handleChange(e, "itemNb")} // Handling change for address number
            label="Item Nb" // Label for the input field
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}
        {/* Full name input field */}
        <Grid sx={{ width: "20%" }}>
          <BasicTextFields
            value={itemsMasterFilter?.itemDesc} // Setting the value from the filter state
            id="itemDesc" // Assigning a unique ID
            onChange={(e) => handleChange(e, "itemDesc")} // Handling change for full name
            label="Item Description" // Label for the input field
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}
        {/* Search button */}
        <Grid sx={{ width: "8%", display: "flex" }}>
          <BasicButtons text={"Search"} onClick={onSearch} />{" "}
          {/* Button to trigger the search */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ItemsInfoFilterReturn; // Exporting the component as default
