// Importing necessary modules from Redux Toolkit and universal-cookie
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

// Initialize cookies to manage persistent user data in the browser
const cookies = new Cookies();

// Define the initial state of the authentication slice
const inventorySliceInitialState = {
  // Retrieve userAll from cookies if available, otherwise initialize as an empty object
  itemsMasterFilter: {
    itemNb: "",
    itemDesc: "",
  },
  itemsMasterData: [],
  contextMenuItemsData: [],
  brandList: [],
  unitsList: [],
  featureTypeList: [],
};

// Create a slice for authentication using Redux Toolkit's createSlice function
const inventorySlice = createSlice({
  name: "inventory", // Name of the slice
  initialState: inventorySliceInitialState, // Initial state defined above

  // Define the reducers (functions to update the state)
  reducers: {
    // Set user credentials in the state and cookies
    setItemsMasterFilter: (state, action) => {
      // Update the state with the new user information
      state.itemsMasterFilter = action.payload;
    },
    setItemsMasterData: (state, action) => {
      // Update the state with the new user information
      state.itemsMasterData = action.payload;
    },
    setBrandList: (state, action) => {
      // Update the state with the new user information
      state.brandList = action.payload;
    },
    setContextMenuItemsData: (state, action) => {
      // Update the state with the new user information
      state.contextMenuItemsData = action.payload;
    },
    setUnitsList: (state, action) => {
      // Update the state with the new user information
      state.unitsList = action.payload;
    },
    setFeatureTypeList: (state, action) => {
      // Update the state with the new user information
      state.featureTypeList = action.payload;
    },
  },
});

// Export the actions to be used in other parts of the application
export const {
  setItemsMasterFilter,
  setItemsMasterData,
  setBrandList,
  setContextMenuItemsData,
  setUnitsList,
  setFeatureTypeList,
} = inventorySlice.actions;

// Export the reducer to be used in the store configuration
export default inventorySlice.reducer;
