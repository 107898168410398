import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../RTKQuery/apiSlice";
import authReducer from "./Reducers/authSliceReducer";
import addressBookReducer from "./Reducers/addressBookReducer";
import inventoryReducer from "./Reducers/inventoryReducer";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    addressBook: addressBookReducer,
    inventory: inventoryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "auth/setSocketLabels",
          "auth/setClient",
          "auth/setSocketAdmin",
          "auth/setSocketProject",
        ],
        ignoredPaths: [
          "auth.socketLabels",
          "auth.client",
          "auth.socketadmin",
          "auth.socketproject",
        ],
      },
    }).concat(apiSlice.middleware), // Adding the API slice middleware,
  devTools: true,
});
