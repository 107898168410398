import { Box } from "@mui/material";
import React from "react";
import DefaultComponent from "../../Common/BoxItems";
import { useSelector } from "react-redux";
// import Hierarchy from "../../Common/DataGrid";

const HomeComponent = ({ selectedMenu }) => {
  return (
    <Box sx={{ paddingX: "5%" }}>
      <DefaultComponent data={selectedMenu} />
      {/* <Hierarchy /> */}
    </Box>
  );
};

export default HomeComponent;
