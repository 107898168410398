import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import "./button.css";

export default function BasicButtons(props) {
  return (
    <Stack spacing={2} direction="row" width={"100%"}>
      <Button {...props} variant="contained" className="basic-button">
        {props.text}
      </Button>
    </Stack>
  );
}
