import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BasicSelect({ label, value, onChange, data = [] }) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl
        fullWidth
        sx={{
          ".css-1q964xs-MuiFormLabel-root-MuiInputLabel-root": {
            color: "#1976d2",
            fontWeight: "bold",
          },
        }}
      >
        <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-select-label`}
          id={`${label}-select`}
          value={value || ""} // Ensure value is not undefined or null
          label={label}
          onChange={onChange}
        >
          {data.length > 0 ? (
            data.map((item) => (
              <MenuItem key={item?.value} value={item?.value}>
                {item?.title}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">
              <em>No Options Available</em>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
