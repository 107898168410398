import React, { useState, useRef } from "react"; // Import necessary hooks from React
import {
  GridComponent, // Main grid component
  ColumnsDirective, // Component for defining grid columns
  ColumnDirective, // Component for defining a single column
  Inject, // Service injection component
  DetailRow, // Service for displaying detail rows
  Page, // Service for pagination
  Sort, // Service for sorting
  Filter, // Service for filtering
  Toolbar, // Service for toolbar functionality
  Edit, // Service for editing rows
  Selection, // Service for selecting rows
  PdfExport, // Service for exporting to PDF
  ExcelExport, // Service for exporting to Excel
  ColumnMenu, // Service for displaying column menu
  ContextMenu, // Service for context menu
  Resize, // Service for resizing columns
} from "@syncfusion/ej2-react-grids"; // Syncfusion grid library
import "./singleGrid.css"; // Importing CSS styles for the grid
import "font-awesome/css/font-awesome.min.css"; // Importing Font Awesome for icons

// SingleHierarchy is a functional component that renders a customizable grid
function SingleHierarchy({
  columns, // Array of column definitions
  data, // Initial data to populate the grid
  primaryKey, // Field name of the primary key
  // openContextMenuPopUp, // Function to open context menu
  // getContextMenuData, // Function to get data for context menu
  selection, // Selection type (Single/Multiple)
  onChanges, // Callback for handling changes in grid
  contextMenu, // Boolean to determine if context menu is enabled
  readOnly,
  setRowSelected,
  contextMenuItems,
  contextMenuClick,
  onDelete,
  handleClose,
}) {
  const [gridData, setGridData] = useState([...data]); // State for grid data
  const gridInstance = useRef(null); // Reference to the grid instance using useRef

  const filterSettings = { type: "Excel" }; // Set filter type to Excel
  const toolbarOptions = ["Add", "Edit", "Delete", "PdfExport", "ExcelExport"]; // Toolbar options

  const editSettings = {
    // Configuration for editing features
    allowDeleting: readOnly ? false : true, // Enable deleting rows
    allowEditing: readOnly ? false : true, // Enable editing rows
    allowAdding: readOnly ? false : true, // Enable adding new rows
    mode: "Dialog", // Use a dialog for editing
  };

  const pageSettings = { pageCount: 5 }; // Pagination settings
  const selectionSettings = {
    // Selection settings
    type: selection, // Type of selection (Single/Multiple)
    checkboxMode: "ResetOnRowClick", // Checkbox behavior
    persistSelection: true, // Persist selection state
  };

  // Template for rendering the primary key in edit mode
  const primaryKeyEditTemplate = (args) => {
    return (
      <input
        type="text"
        disabled={true} // Disable editing for the primary key field
        value={args[primaryKey] || ""} // Set value to the primary key
        className="e-field e-input" // Apply CSS classes
      />
    );
  };

  // Event handler for action initiation (add, edit, delete)
  const actionBegin = (args) => {
    if (args.requestType === "delete") {
      // Check your condition here
      const shouldCancel = window.confirm(
        "Are you sure you want to delete this row?"
      );
      if (!shouldCancel) {
        args.cancel = true; // Cancel the delete action
      } else {
        const updatedData = gridData
          .filter(
            (row) =>
              !args.data.some((item) => item[primaryKey] === row[primaryKey]) // Filter out deleted rows
          )
          .map((item) => ({ ...item })); // Clone each row to maintain immutability

        setGridData(updatedData); // Update state with the filtered data
        if (onDelete) {
          onDelete();
        }
      }
      if (handleClose) {
        handleClose();
      }

      // Handle delete action
    }
  };

  // Event handler for action completion (after add, edit, delete)
  const actionComplete = (args) => {
    onChanges(args, gridInstance); // Call onChanges callback

    if (args.requestType === "save" || args.requestType === "delete") {
      if (gridInstance.current) {
        setGridData([...gridData]); // Force grid data re-render
        gridInstance.current.refresh(); // Refresh the grid to update its view
      }
    }
  };

  // Context menu items definition
  // const contextMenuItems = [
  //   { text: "Emails", target: ".e-content", id: "emails" }, // Menu item for emails
  //   { text: "Phone Numbers", target: ".e-content", id: "phoneNumbers" }, // Menu item for phone numbers
  // ];

  // Event handler for context menu click
  const contextMenuClicking = (args) => {
    // if (gridInstance && args.item.id === "emails") {
    //   getContextMenuData("searchEmail", args?.rowInfo?.rowData?.[primaryKey]); // Fetch data for email
    //   openContextMenuPopUp("emails", args?.rowInfo?.rowData); // Open context menu for emails
    // } else if (gridInstance && args.item.id === "phoneNumbers") {
    //   getContextMenuData("searchPhone", args?.rowInfo?.rowData?.[primaryKey]); // Fetch data for phone number
    //   openContextMenuPopUp("phoneNumbers", args?.rowInfo?.rowData); // Open context menu for phone numbers
    // }
    contextMenuClick(args, gridInstance);
  };

  const rowSelected = (args) => {
    if (setRowSelected) {
      setRowSelected(args?.data);
    }
  };

  function toolbarClick(args) {
    if (args.item.id === "MasterDetailsExport_excelexport") {
      if (gridInstance.current) {
        gridInstance.current.excelExport({ hierarchyExportMode: "All" });
      }
    }
    if (args.item.id === "MasterDetailsExport_pdfexport") {
      if (gridInstance.current) {
        gridInstance.current.pdfExport({ hierarchyExportMode: "All" });
      }
    }
  }

  return (
    <div className="control-pane" style={{ position: "relative" }}>
      {" "}
      {/* Main container for the grid */}
      <div className="control-section">
        <GridComponent
          dataSource={gridData} // Use state-managed data as the data source
          id="MasterDetailsExport" // Unique ID for the grid
          ref={gridInstance} // Attach the ref to the grid
          allowSorting={true} // Enable sorting
          showColumnMenu={true} // Show column menu
          allowFiltering={true} // Enable filtering
          filterSettings={filterSettings} // Set filter settings
          toolbar={readOnly ? [] : toolbarOptions} // Set toolbar options
          allowPaging={true} // Enable paging
          editSettings={editSettings} // Set edit settings
          pageSettings={pageSettings} // Set pagination settings
          selectionSettings={selectionSettings} // Set selection settings
          allowPdfExport={readOnly ? false : true} // Allow PDF export
          allowExcelExport={readOnly ? false : true} // Allow Excel export
          actionBegin={actionBegin} // Set action begin handler
          actionComplete={actionComplete} // Set action complete handler
          contextMenuItems={contextMenu && contextMenuItems} // Set context menu items if enabled
          contextMenuClick={contextMenu && contextMenuClicking} // Set context menu click handler if enabled
          rowSelected={rowSelected}
          toolbarClick={toolbarClick.bind(this)}
        >
          <ColumnsDirective>
            {selection !== "Single" && (
              <ColumnDirective type="checkbox" width="50" />
            )}
            {/* Checkbox column for selection */}
            {columns?.map(
              (
                cd,
                i // Iterate over column definitions
              ) => (
                <ColumnDirective
                  key={i} // Unique key for each column
                  field={cd?.field} // Field name for the column
                  headerText={cd?.headerText} // Header text for the column
                  width={cd?.width} // Width for the column
                  isPrimaryKey={cd?.isPrimaryKey} // Check if this column is the primary key
                  textAlign="left" // Align text to the left
                  allowEditing={cd?.isPrimaryKey ? false : cd?.allowEditing} // Disable editing for primary key
                  allowAdding={cd?.allowAdding} // Allow adding to this column
                  editType={cd?.editType} // Specify the type of edit (e.g., dropdown)
                  edit={
                    // Conditional edit template
                    cd?.field === primaryKey
                      ? { create: primaryKeyEditTemplate } // Use custom template for primary key
                      : cd?.edit // Use default edit template
                  }
                  editTemplate={
                    // Conditional edit template for rendering
                    cd?.field === primaryKey
                      ? primaryKeyEditTemplate // Use custom template for primary key
                      : cd?.editTemplate // Use default edit template
                  }
                />
              )
            )}
          </ColumnsDirective>
          {!readOnly && (
            <Inject
              services={[
                // Inject required services for the grid functionality
                DetailRow,
                Page,
                Sort,
                Filter,
                Toolbar,
                Edit,
                Selection,
                PdfExport,
                ExcelExport,
                ColumnMenu,
                ContextMenu,
                Resize,
              ]}
            />
          )}
        </GridComponent>
      </div>
    </div>
  );
}

export default SingleHierarchy; // Export the SingleHierarchy component for use in other parts of the application
