import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./checkbox.css";

export default function CheckboxLabels(props) {
  return (
    <FormGroup
      sx={{ display: "flex", alignItems: "center" }}
      className="checkbox-label"
    >
      <FormControlLabel {...props} control={<Checkbox defaultChecked />} />
    </FormGroup>
  );
}
