import React, { useState } from "react";
import OTPInput from "../../../Common/OTPInput"; // Importing custom OTP input component
import BasicTextFields from "../../../Common/TextField"; // Importing custom TextField component for inputs
import { CircularProgress, Grid } from "@mui/material"; // Importing CircularProgress for loading indicator and Grid for layout
import BasicButtons from "../../../Common/Button"; // Importing custom Button component
import { useChangingPasswordMutation } from "../../../../Services/RTKQuery/auth/authApiSlice"; // Importing mutation hook for changing password API
import { setNotificationSnackBar } from "../../../../Services/Redux/Reducers/authSliceReducer"; // Redux action for notification snackbar
import { useDispatch } from "react-redux"; // Importing Redux hook for dispatch
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Importing arrow back icon from material icons
import { useNavigate } from "react-router-dom"; // Importing hook to navigate between routes

const ChangePasswordComponent = ({ onCloseCheck, id }) => {
  // Local state for managing password inputs and OTP code
  const [changePassword, setChangePassword] = useState({
    code: "", // OTP code
    password: "", // New password
    confirmPassword: "", // Confirm password
  });
  const [errorMsg, setErrorMsg] = useState(""); // Local state to manage error messages
  const dispatch = useDispatch(); // Initializing dispatch for Redux actions
  const navigate = useNavigate(); // Hook to navigate to different routes

  // Mutation for changing password using the API
  const [changingPassword, { isLoading, isError, error }] =
    useChangingPasswordMutation();

  // Function to handle input changes and update local state
  const onChange = (e, item) => {
    setChangePassword({
      ...changePassword,
      [item]: e, // Update the respective field in the state
    });
    // Check if password and confirm password match, reset error message if they do
    if (item === "password" && e === changePassword?.confirmPassword) {
      setErrorMsg("");
    } else if (item === "confirmPassword" && e === changePassword?.password) {
      setErrorMsg("");
    }
  };

  // Function to check if passwords match when the confirm password field loses focus
  const onBlur = () => {
    if (changePassword?.password !== changePassword?.confirmPassword) {
      setErrorMsg("Please make sure your passwords match!"); // Display error message if passwords do not match
    }
  };

  // Function to handle form submission when the "Confirm" button is clicked
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload on form submission

    // Attempt to change the password by calling the API
    const change = await changingPassword({
      code: changePassword?.code, // OTP code from input
      requestId: id, // Request ID from the component prop
      password: changePassword?.password, // New password from input
    }).unwrap();

    // If the API response indicates success, close the modal and navigate to the home page
    if (change?.message?.type === "success") {
      onCloseCheck(); // Close the modal
      navigate("/"); // Navigate to home page
    }

    // Dispatch a notification based on the response message
    dispatch(
      setNotificationSnackBar({
        open: true, // Show the snackbar
        message: change?.message?.text, // Message from the API
        type: change?.message?.type, // Type of message (e.g., success or error)
      })
    );
  };

  // Inline styles for label text
  const labels = {
    color: "black",
    fontSize: "smaller",
  };

  // Inline styles for error messages
  const errorStyle = {
    color: "#b00000",
    fontSize: "small",
  };

  return (
    <div
      style={{
        width: "75%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Display form if not loading */}
      {!isLoading && (
        <Grid sx={{ width: "30%" }}>
          {/* Back button */}
          <div
            style={{
              backgroundColor: "black",
              width: "8%",
              borderRadius: "7.5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={onCloseCheck} // Close modal when back button is clicked
          >
            <ArrowBackIcon sx={{ height: "1.5em" }} /> {/* Back icon */}
          </div>

          {/* Heading and instructions */}
          <div
            style={{ marginBottom: "5%", textAlign: "center", color: "black" }}
          >
            <h1>Change Password</h1>
            <h6>Please enter verification code</h6>
          </div>

          {/* OTP input field */}
          <Grid sx={{ marginBottom: "6%" }}>
            <OTPInput
              onChange={(e) => onChange(e, "code")} // Handle OTP input change
              value={changePassword?.code} // Value from local state
            />
          </Grid>

          {/* New password input field */}
          <Grid>
            <Grid sx={labels}>New Password</Grid>
            <BasicTextFields
              value={changePassword?.password} // Value from local state
              id="password"
              onChange={(e) => onChange(e?.target?.value, "password")} // Handle password input change
              placeholder="New Password"
              type="password" // Input type is password
              sx={{
                backgroundColor: "white",
                ".MuiInputBase-input": {
                  padding: "3%",
                },
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                borderRadius: "7.5px",
              }}
            />
          </Grid>

          {/* Confirm password input field */}
          <Grid sx={{ marginTop: "6%" }}>
            <Grid sx={labels}>Confirm Password</Grid>
            <BasicTextFields
              value={changePassword?.confirmPassword} // Value from local state
              id="confirmPassword"
              onChange={(e) => onChange(e?.target?.value, "confirmPassword")} // Handle confirm password input change
              onBlur={onBlur} // Check if passwords match when field loses focus
              placeholder="Confirm Password"
              type="password" // Input type is password
              sx={{
                backgroundColor: "white",
                ".MuiInputBase-input": {
                  padding: "3%",
                },
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                borderRadius: "7.5px",
              }}
            />
          </Grid>

          {/* Display error message if passwords do not match */}
          {errorMsg && <Grid sx={errorStyle}>{errorMsg}</Grid>}

          {/* Submit button */}
          <Grid sx={{ marginTop: "6%" }}>
            <BasicButtons
              onClick={handleSubmit} // Call submit function when clicked
              text={
                !isLoading ? (
                  "Confirm"
                ) : (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "20px !important",
                      height: "20px !important",
                    }}
                  />
                )
              }
              disabled={
                changePassword?.code === "" || // Disable button if any input is empty
                changePassword?.password === "" ||
                changePassword?.confirmPassword === "" ||
                errorMsg !== "" || // Or if there is an error message
                changePassword?.password !== changePassword?.confirmPassword // Or if passwords do not match
              }
            />
          </Grid>
        </Grid>
      )}

      {/* Display loading indicator if loading */}
      {isLoading && (
        <div
          style={{
            width: "75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "black",
              width: "200px !important",
              height: "200px !important",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ChangePasswordComponent; // Exporting the component as default
